import React from "react";
import {Link} from "gatsby";
import SEO from "../components/seo";

const PrivacyPolicy = () => {
  return (
    <>
      <SEO title={"Polityka prywatności"} />
      <p><strong>Polityka prywatności i wykorzystywania plików „cookies” w Systemie Zamówień Internetowych
        www.odbojnice.sklep.pl</strong></p>
      <p><strong>Informacje ogólne</strong></p>
      <p>Niniejszy dokument określa zasady Polityki prywatności w Systemie Zamówień Internetowych (dalej jako "System
        Zamówień").
        Administratorem Systemu Zamówień jest Mariusz Adasiak prowadzący działalność gospodarczą pod firmą Mariusz
        Adasiak PRZEDSIĘBIORSTWO HANDLOWO - USŁUGOWE MARTECH wpisany do rejestru przedsiębiorców Centralnej Ewidencji i
        Informacji o Działalności Gospodarczej prowadzonej przez Ministra Przedsiębiorczości i Technologii pod adresem
        ul. Kolejowa nr 22, 58-340 Głuszyca, NIP 8851000346, REGON 020958561.</p>
      <p>Wyrazy użyte z dużej litery mają znaczenie nadane im w Regulaminie niniejszego Systemu Zamówień.</p>
      <p>Dane osobowe zbierane przez Administratora Systemu Zamówień są przetwarzane zgodnie z przepisami
        Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.04.2016 r. w sprawie ochrony osób fizycznych w
        związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia
        dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz. Urz. UE L 119, s. 1), zwanego dalej: RODO.</p>
      <p>Administrator Systemu Zamówień dokłada szczególnych starań w celu ochrony prywatności i informacji mu
        przekazanych, a dotyczących Klientów Systemu Zamówień. Administrator z należytą starannością dobiera i
        stosuje odpowiednie środki techniczne, w tym o charakterze programistycznym i organizacyjnym, zapewniające
        ochronę przetwarzanych danych, w szczególności zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym,
        ujawnieniem, utraceniem i zniszczeniem, nieuprawnioną modyfikacją, jak również przed ich przetwarzaniem z
        naruszeniem obowiązujących przepisów prawa.</p>
      <p>Adresatem Usług dostępnych na stronie internetowej (w szczególności możliwości złożenia Zamówienia w Systemie Zamówień)
        nie są osoby poniżej 16 roku życia. Administrator danych osobowych nie przewiduje celowego gromadzenia danych
        dotyczących osób poniżej 16 roku życia.</p>
      <p><strong>Dane osobowe</strong></p>
      <p><strong>Administrator danych osobowych</strong></p>
      <p>Administratorem Twoich danych osobowych jest:</p>
      <p>Mariusz Adasiak PRZEDSIĘBIORSTWO HANDLOWO - USŁUGOWE MARTECH<br/>
        ul. Kolejowa 22, 58-340 Głuszyca</p>
      <p>W sprawie Twoich danych osobowych możesz skontaktować się z Administratorem danych osobowych za pomocą:</p>
      <ul>
        <li><p>poczty elektronicznej: kontakt@martech-sg.pl,</p></li>
        <li><p>poczty tradycyjnej: ul. Kolejowa 22, 58-340 Głuszyca,</p></li>
        <li><p>telefonu: +48 600 819 114.</p></li>
      </ul>
      <p><strong>Cele i podstawy prawne przetwarzania danych osobowych</strong></p>
      <p>Administrator danych osobowych przetwarza Twoje dane osobowe w następujących celach i zakresie:</p>
      <ul>
        <li><p>w celu podjęcia działań związanych ze złożeniem zapytania ofertowego na Twoje żądanie, tj. dane podane w
          formularzu rejestracyjnym w Sklepie Internetowym, tzn. adres e-mail oraz ustanowione hasło, płeć; jeżeli
          rejestrujesz się przy zakupie Towarów, zbieramy Twoje imię i nazwisko i dane podane w celu realizacji
          Zamówienia takie jak adres do wysyłki; w celu świadczenia Usług wymagających założenia Konta takich jak:
          prowadzenie historii Zamówień, informowanie o statusie realizacji Zamówienia, przetwarzamy Twoje dane podane w
          Koncie oraz przy zakupie Towarów;</p></li>
      </ul>
      <p><strong>Kategorie odnośnych danych osobowych</strong></p>
      <p>Administrator danych osobowych przetwarza następujące kategorie odnośnych danych osobowych:</p>
      <ul>
        <li><p>dane kontaktowe;</p></li>
      </ul>
      <p><strong>Dobrowolność podania danych osobowych</strong></p>
      <p>Podanie przez Ciebie wymaganych danych osobowych jest dobrowolne i stanowi warunek świadczenia Usług przez
        Administratora danych osobowych za pośrednictwem Sklepu Internetowego.</p>
      <p><strong>Czas przetwarzania danych</strong></p>
      <p>Dane osobowe będą przetwarzane przez okres niezbędny do realizacji Zamówień, Usług, działań marketingowych oraz
        innych Usług wykonywanych dla Klienta. Dane osobowe będą usunięte w następujących przypadkach:</p>
      <ul>
        <li><p>kiedy osoba, której dane dotyczą zwróci się o ich usunięcie lub wycofa udzieloną zgodę;</p></li>
        <li><p>kiedy osoba, której dane dotyczą nie podejmuje działań przez ponad 10 lat (kontakt nieaktywny);</p></li>
        <li><p>po uzyskaniu informacji, że przechowywane dane są nieaktualne lub niedokładne.</p></li>
      </ul>
      <p>Niektóre dane w zakresie: adres e-mail, imię i nazwisko, mogą być przechowywane przez okres kolejnych 3 lat dla
        celów dowodowych, rozpatrywania reklamacji, skarg oraz roszczeń związanych z Usługami świadczonymi przez Sklep
        Internetowy - dane te nie będą wykorzystywane do celów marketingowych.</p>
      <p>Dane dotyczące Zamówień Towarów i Usług płatnych oraz konkursów w zakresie obejmującym dane niezbędne do
        prowadzenia ksiąg rachunkowych będą przechowywane przez okres 5 lat licząc od końca roku kalendarzowego, w
        którym upłynął termin płatności podatku.</p>
      <p>Dane dotyczące Klientów niezalogowanych przechowujemy przez czas odpowiadający cyklowi życia zapisanych na
        urządzeniach plików „cookies” lub do czasu ich usunięcia w urządzeniu Klienta przez Klienta.</p>
      <p>Twoje dane osobowe dotyczące preferencji, zachowań i wyboru treści marketingowych mogą być wykorzystywane jako
        podstawa do podjęcia zautomatyzowanych decyzji w celu określenia możliwości sprzedażowych Sklepu
        internetowego.</p>
      <p><strong>Odbiorcy danych osobowych</strong></p>
      <p>Przekazujemy Twoje dane osobowe następującym kategoriom odbiorców:</p>
      <ul>
        <li><p>organom państwowym, np. prokuratura, Policja, PUODO, Prezes UOKiK, jeżeli się o to do nas zwrócą,</p>
        </li>
        <li><p>dostawcom Usług, z których korzystamy przy prowadzeniu Sklepu Internetowego np. w celu realizacji
          Zamówienia. W zależności od uzgodnień umownych i okoliczności, podmioty te działają na nasze zlecenie albo
          samodzielnie określają cele i sposoby ich przetwarzania, wykaz dostawców znajdziesz na stronie internetowej
          naszego Sklepu internetowego pod linkiem: <Link to={"/prywatnosc-dostawcy-uslug"}>dostawcy usług</Link>.</p></li>
      </ul>
      <p><strong>Prawa osoby, której dane dotyczą</strong></p>
      <p>Na podstawie RODO masz prawo do:</p>
      <ul>
        <li><p>żądania dostępu do swoich danych osobowych;</p></li>
        <li><p>żądania sprostowania swoich danych osobowych;</p></li>
        <li><p>żądania usunięcia swoich danych osobowych;</p></li>
        <li><p>żądania ograniczenia przetwarzania danych osobowych;</p></li>
        <li><p>wniesienia sprzeciwu wobec przetwarzania danych osobowych;</p></li>
        <li><p>żądania przenoszenia danych osobowych.</p></li>
      </ul>
      <p>Administrator danych osobowych bez zbędnej zwłoki – a w każdym razie w terminie miesiąca od otrzymania żądania
        – udziela Ci informacji o działaniach podjętych w związku ze zgłoszonym przez Ciebie żądaniem. W razie potrzeby
        termin miesięczny może być przedłużony o kolejne dwa miesiące z uwagi na skomplikowany charakter żądania lub
        liczbę żądań.</p>
      <p>W każdym wypadku Administrator danych osobowych poinformuje Cię o takim przedłużeniu w terminie miesiąca od
        otrzymania żądania z podaniem przyczyn opóźnienia.</p>
      <p><strong>Prawo dostępu do danych osobowych (art. 15 RODO)</strong></p>
      <p>Masz prawo uzyskania od Administratora danych osobowych informacji czy Twoje dane osobowe są przetwarzane.</p>
      <p>Jeżeli Administrator przetwarza Twoje dane osobowe masz prawo do:</p>
      <ul>
        <li><p>dostępu do danych osobowych;</p></li>
        <li><p>uzyskania informacji o celach przetwarzania, kategoriach przetwarzanych danych osobowych, o odbiorcach
          lub kategoriach odbiorców tych danych, planowanym okresie przechowywania Twoich danych lub o kryteriach
          ustalania tego okresu, o prawach przysługujących Ci na mocy RODO oraz o prawie wniesienia skargi do organu
          nadzorczego, o źródle tych danych, o zautomatyzowanym podejmowaniu decyzji, w tym o profilowaniu oraz o
          zabezpieczeniach stosowanych w związku z przekazaniem tych danych poza Unię Europejską;</p></li>
        <li><p>uzyskania kopii swoich danych osobowych.</p></li>
      </ul>
      <p>Jeśli chcesz zażądać dostępu do swoich danych osobowych, zgłoś swoje żądanie na adres:
        kontakt@martech-sg.pl.</p>
      <p><strong>Prawo do sprostowania danych osobowych (art. 16 RODO)</strong></p>
      <p>Jeżeli Twoje dane osobowe są nieprawidłowe, masz prawo żądania od Administratora niezwłocznego sprostowania
        Twoich danych osobowych.</p>
      <p>Masz też prawo do żądania od Administratora uzupełnienia Twoich danych osobowych.</p>
      <p>Jeśli chcesz zażądać sprostowania danych osobowych lub ich uzupełnienia, zgłoś swoje żądanie na adres:
        kontakt@martech-sg.pl.</p>
      <p>Jeżeli dokonałeś rejestracji w Sklepie Internetowym, swoje dane osobowe możesz sprostować i uzupełniać
        samodzielnie po zalogowaniu się w Sklepie Internetowym.</p>
      <p><strong>Prawo do usunięcia danych osobowych, tzw. “prawo do bycia zapomnianym” (art. 17 RODO)</strong></p>
      <p>Masz prawo żądania od Administratora danych osobowych usunięcia swoich danych osobowych, gdy:</p>
      <ul>
        <li><p>Twoje dane osobowe przestały być niezbędne do celów, w których zostały zebrane lub w inny sposób
          przetwarzane;</p></li>
        <li><p>wycofałeś określoną zgodę, w zakresie w jakim dane osobowe były przetwarzane w oparciu o Twoją zgodę;</p>
        </li>
        <li><p>Twoje dane osobowe były przetwarzane niezgodnie z prawem;</p></li>
        <li><p>wniosłeś/wniosłaś sprzeciw wobec przetwarzania Twoich danych osobowych na potrzeby marketingu
          bezpośredniego, w tym profilowania, w zakresie w jakim przetwarzanie danych osobowych jest związane z
          marketingiem bezpośrednim;</p></li>
        <li><p>wniosłeś/wniosłaś sprzeciw wobec przetwarzania Twoich danych osobowych w związku z przetwarzaniem
          niezbędnym dla wykonania zadania realizowanego w interesie publicznym lub przetwarzania niezbędnego dla celów
          wynikających z prawnie uzasadnionych interesów realizowanych przez Administratora danych osobowych lub stronę
          trzecią.</p></li>
      </ul>
      <p>Pomimo zgłoszenia żądania usunięcia danych osobowych Administrator danych osobowych może przetwarzać Twoje dane
        dalej w celu ustalenia, dochodzenia lub obrony roszczeń o czym zostaniesz poinformowany/poinformowana.</p>
      <p>Jeśli chcesz zażądać usunięcia swoich danych osobowych, zgłoś swoje żądanie na adres:
        kontakt@martech-sg.pl.</p>
      <p><strong>Prawo do zgłoszenia żądania ograniczenia przetwarzania danych osobowych (art. 18 RODO)</strong></p>
      <p>Masz prawo do żądania ograniczenia przetwarzania Twoich danych osobowych, gdy:</p>
      <ul>
        <li><p>kwestionujesz prawidłowość swoich danych osobowych – Administrator danych osobowych ograniczy
          przetwarzanie Twoich danych osobowych na czas pozwalający sprawdzić prawidłowość tych danych;</p></li>
        <li><p>gdy przetwarzanie Twoich danych jest niezgodne z prawem, a zamiast usunięcia danych osobowych zażądasz
          ograniczenia przetwarzania Twoich danych osobowych;</p></li>
        <li><p>Twoje dane osobowe przestały być potrzebne do celów przetwarzania, ale są one potrzebne w celu ustalenia,
          dochodzenia lub obrony Twoich roszczeń;</p></li>
        <li><p>gdy zgłosiłeś/zgłosiłaś sprzeciw wobec przetwarzania Twoich danych osobowych – do czasu stwierdzenia czy
          prawnie uzasadnione interesy po stronie Administratora danych osobowych są nadrzędne wobec podstaw wskazanych
          w Twoim sprzeciwie.</p></li>
      </ul>
      <p>Jeśli chcesz zażądać ograniczenia przetwarzania swoich danych osobowych, zgłoś swoje żądanie na adres:
        kontakt@martech-sg.pl.</p>
      <p><strong>Prawo do sprzeciwu wobec przetwarzania danych osobowych (art. 21 RODO)</strong></p>
      <p>Masz prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzania Twoich danych osobowych, w tym profilowania,
        w związku z:</p>
      <ul>
        <li><p>przetwarzaniem niezbędnym dla wykonania zadania realizowanego w interesie publicznym lub przetwarzania
          niezbędnego dla celów wynikających z prawnie uzasadnionych interesów realizowanych przez Administratora danych
          osobowych lub stronę trzecią;</p></li>
        <li><p>przetwarzaniem na potrzeby marketingu bezpośredniego.</p></li>
      </ul>
      <p>Jeśli chcesz zgłosić sprzeciw wobec przetwarzania Twoich danych osobowych, zgłoś swoje żądanie na adres:
        kontakt@martech-sg.pl.</p>
      <p><strong>Prawo do żądania przenoszenia danych osobowych (art. 20 RODO)</strong></p>
      <p>Masz prawo do otrzymania od Administratora danych osobowych swoich danych osobowych w ustrukturyzowanym,
        powszechnie używanym formacie nadającym się do odczytu maszynowego oraz przesłać je innemu administratorowi
        danych osobowych.</p>
      <p>Możesz również zażądać, aby to Administrator danych osobowych przesłał bezpośrednio Twoje dane osobowe innemu
        administratorowi (o ile jest to technicznie możliwe).</p>
      <p>Jeśli chcesz zażądać przeniesienia swoich danych osobowych, zgłoś swoje żądanie na adres:
        kontakt@martech-sg.pl.</p>
      <p><strong>Prawo do cofnięcia zgody</strong></p>
      <p>Możesz cofnąć udzieloną zgodę na przetwarzanie swoich danych osobowych w dowolnym momencie.</p>
      <p>Cofnięcie zgody na przetwarzanie danych osobowych nie ma wpływu na zgodność z prawem przetwarzania dokonanego
        na podstawie Twojej zgody przed jej cofnięciem.</p>
      <p>Jeśli chcesz cofnąć zgodę na przetwarzanie swoich danych osobowych, zgłoś swoje żądanie na adres:
        kontakt@martech-sg.pl lub skorzystać z odpowiednich funkcjonalności w Koncie.</p>
      <p><strong>Skarga do organu nadzorczego</strong></p>
      <p>Jeżeli sądzisz, że przetwarzanie Twoich danych osobowych narusza RODO, masz prawo złożenia skargi do organu
        nadzorczego, w szczególności w państwie członkowskim swojego zwykłego pobytu, swojego miejsca pracy lub miejsca
        popełnienia domniemanego naruszenia.</p>
      <p>W Polsce organem nadzorczym w rozumieniu RODO jest Prezes Urzędu Ochrony Danych Osobowych (PUODO).</p>
      <p><strong>Pliki „cookies”</strong></p>
      <p><strong>Informacje ogólne</strong></p>
      <p>Podczas przeglądania stron internetowych Sklepu Internetowego są używane pliki "cookies", zwane dalej Cookies,
        czyli niewielkie informacje tekstowe, które są zapisywane w Twoim urządzeniu końcowym w związku z korzystaniem
        ze Sklepu Internetowego. Ich stosowanie ma na celu poprawne działanie stron internetowych Sklepu
        Internetowego.</p>
      <p>Pliki te pozwalają zidentyfikować oprogramowanie wykorzystywane przez Ciebie i dostosować Sklep Internetowy
        indywidualnie do Twoich potrzeb.</p>
      <p>Pliki Cookies zazwyczaj zawierają nazwę domeny, z której pochodzą, czas przechowywania ich na urządzeniu oraz
        przypisaną wartość.</p>
      <p><strong>Bezpieczeństwo</strong></p>
      <p>Stosowane przez nas pliki Cookies są bezpieczne dla Twoich urządzeń. W szczególności nie jest możliwe
        przedostanie się do Twoich urządzeń poprzez pliki Cookies wirusów lub innego niechcianego oprogramowania lub
        oprogramowania złośliwego.</p>
      <p><strong>Rodzaje plików Cookies</strong></p>
      <p>Stosujemy dwa rodzaje plików Cookies:</p>
      <ul>
        <li><p>Cookies sesyjne: są przechowywane na Twoim urządzeniu i pozostają tam do momentu zakończenia sesji danej
          przeglądarki. Zapisane informacje są wówczas trwale usuwane z pamięci Twojego urządzenia. Mechanizm Cookies
          sesyjnych nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji poufnych z Twojego
          urządzenia.</p></li>
        <li><p>Cookies trwałe: są przechowywane na Twoim urządzeniu i pozostają tam do momentu ich skasowania.
          Zakończenie sesji danej przeglądarki lub wyłączenie urządzenia nie powoduje ich usunięcia z Twojego
          urządzenia. Mechanizm Cookies trwałych nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych
          informacji poufnych z Twojego urządzenia.</p></li>
      </ul>
      <p><strong>Cele</strong></p>
      <p>Wykorzystujemy także pliki Cookies podmiotów zewnętrznych w następujących celach:</p>
      <ul>
        <li><p>konfiguracji Sklepu Internetowego;</p></li>
        <li><p>prezentacji Świadectwa Zgodności, za pośrednictwem serwisu internetowego solidnyregulamin.pl, którego
          administratorem jest GP Kancelaria Poniatowska-Maj Strzelec-Gwóźdź sp. p. z siedzibą w Krakowie, Polityka
          Prywatności dostępna jest pod następującym linkiem: - http://solidnyregulamin.pl/polityka-prywatnosci/;</p>
        </li>
        <li><p>tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Klienci Sklepu Internetowego korzystają ze
          stron internetowych, co umożliwia ulepszanie ich struktury i zawartości za pośrednictwem narzędzi
          analitycznych Google Analytics, których administratorem jest Google Ireland Ltd. z siedzibą w Irlandii,
          Polityka ochrony prywatności Google jest dostępna pod następującymi linkami:
          http://www.google.com/intl/pl/policies/privacy/, http://www.google.com/intl/pl/policies/privacy/partners/.</p>
        </li>
      </ul>
      <p>Aby poznać zasady korzystania z Cookies, zalecamy zapoznanie się z politykami prywatności wyżej wskazanych
        firm.</p>
      <p>Cookies mogą być wykorzystane przez sieci reklamowe, w szczególności sieć Google, do wyświetlenia reklam
        dopasowanych do Twoich preferencji. W tym celu mogą zostać zachowane informacje o sposobie poruszania się przez
        Ciebie w sieci lub czasie skorzystania ze strony internetowej.</p>
      <p>Aby przeglądać i edytować informacje o Twoich preferencjach, gromadzonych przez sieć reklamową Google, możesz
        skorzystać z narzędzia zamieszczonego pod linkiem https://www.google.com/ads/preferences/.</p>
      <p>Za pomocą ustawień przeglądarki internetowej lub za pomocą konfiguracji Usługi, możesz samodzielnie i w każdym
        czasie zmienić ustawienia dotyczące Cookies, określając warunki ich przechowywania i uzyskiwania dostępu przez
        Cookies do Twojego urządzenia. Ustawienia te możesz zmienić tak, aby blokować automatyczną obsługę Cookies w
        ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu na Twoim urządzeniu.
        Szczegółowe informacje o możliwości i sposobach obsługi Cookies dostępne są w ustawieniach Twojego
        oprogramowania (przeglądarki internetowej).</p>
    </>
  );
}

export default PrivacyPolicy;